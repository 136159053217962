<template>
  <b-overlay :show="loading">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Enroll</span>
        </template>

        <b-card no-body>
          <b-overlay :show="loading">
            <b-card-body>
              <b-card-text class="blog-content-truncate">
                <b-row>
                  <b-col md="3" sm="3" class="">
                    <b-button
                      variant="outline-primary"
                      @click="getJadwal()"
                      v-b-modal.modal-sm
                      class="btn-icon"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-25"
                      />Calculate</b-button
                    >
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="3" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                      <label class="d-inline-block text-sm-left mr-50"
                        >Per page</label
                      >
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" sm="8" class="my-1">
                    <!-- <b-form-group label="Sort" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-50">
                      <template v-slot:first>
                        <option value="">-- none --</option>
                      </template>
                    </b-form-select>
                    <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-30">
                      <option :value="false">Asc</option>
                      <option :value="true">Desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group> -->
                  </b-col>
                  <b-col md="5" class="my-1">
                    <b-form-group label-for="filterInput" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                          id="filterInput"
                          v-model="filter"
                          type="search"
                          placeholder="Search......"
                        />
                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="2" class="my-1">
              <b-button variant="primary" size="sm"
                ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
              >
            </b-col> -->

                  <b-col cols="12">
                    <b-table
                      striped
                      small
                      hover
                      responsive
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      :fields="fields"
                      :items="dataMember"
                    >
                      <!-- A virtual column -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <template #cell(user_name)="{ item }">
                        <strong>{{ item.user_name }}</strong>
                      </template>

                      <!-- A custom formatted column -->
                      <template #cell(action)="{ item }">
                        <b-button
                          v-b-tooltip.hover.left="'Reset Hasil'"
                          variant="outline-info"
                          class="btn-icon"
                          @click.prevent="openResetModal(item)"
                        >
                          <feather-icon icon="RotateCwIcon"
                        /></b-button>
                        <b-button
                          v-b-tooltip.hover.left="'Reschedule'"
                          variant="outline-info"
                          class="btn-icon ml-1"
                          @click.prevent="openRescheduleModal(item)"
                        >
                          <feather-icon icon="CalendarIcon"
                        /></b-button>
                      </template>

                      <!-- Optional default data cell scoped slot -->
                      <template #cell()="{ field, item }">
                        <section v-if="field.key.match(/ujian_/gi)">
                          {{ getNilaiUjian(field.label, item) }}
                        </section>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      size="sm"
                      class="my-0"
                    />
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-overlay>
        </b-card>
        <!-- Modal-Reschedule -->
        <b-modal
          no-close-on-backdrop
          no-close-on-esc
          id="modal-reschedule"
          centered
          title="Atur Ulang Jadwal"
        >
          <b-card-text>
            <b-form>
              <b-form-group label="Peserta">
                <b-form-input readonly :value="form.user_name"></b-form-input>
              </b-form-group>
              <b-form-group
                label="Pilih Ujian yang akan di Reschedule"
                label-for="ujian"
              >
                <v-select
                  class="select-size-sm w-full"
                  label="text"
                  v-model="form.ujian_id"
                  :clearable="true"
                  :options="subtestList"
                />
              </b-form-group>
              <b-form-group
                label="Tanggal & Waktu Mulai"
                label-for="start_time"
              >
                <flat-pickr
                  v-model="form.start_time"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i:S',
                    time_24hr: true,
                  }"
                />
              </b-form-group>
              <b-form-group label="Tanggal & Waktu Akhir" label-for="end_time">
                <flat-pickr
                  v-model="form.end_time"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i:S',
                    time_24hr: true,
                  }"
                />
              </b-form-group>
            </b-form>
          </b-card-text>

          <template #modal-footer>
            <div class="w-100">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="outline-success"
                class="mr-1 btn-icon"
                @click.prevent="reschedule"
              >
                <feather-icon icon="SaveIcon" class="mr-25" />Reschedule
              </b-button>
            </div>
          </template>
        </b-modal>

        <!-- Modal reset -->
        <b-modal
          id="modal-reset"
          no-close-on-backdrop
          no-close-on-esc
          centered
          title="Reset Hasil Subject Mapel"
        >
          <b-card-text>
            <b-form>
              <b-form-group label="Peserta">
                <b-form-input
                  readonly
                  :value="formReset.user_name"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Pilih Ujian yang akan di reset">
                <v-select
                  class="select-size-sm w-full"
                  label="text"
                  v-model="formReset.ujian_id"
                  :clearable="true"
                  :options="subtestList"
                />
              </b-form-group>
            </b-form>
          </b-card-text>

          <template #modal-footer>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="outline-success"
              class="mr-1 btn-icon"
              @click.prevent="resetResult"
            >
              <feather-icon icon="RotateIcon" class="mr-25" />Reset Hasil
            </b-button>
          </template>
        </b-modal>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Rank</span>
        </template>

        <section v-if="jadwal">
          <component :is="currentRasionalisasi"></component>
        </section>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BTabs,
  BTab,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  VBTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import HasilSma from "./hasil/sma.vue";
import HasilUtbk from "./hasil/utbk.vue";
import HasilKedinasan from "./hasil/kedinasan.vue";
import HasilMandiri from "./hasil/mandiri.vue";
import HasilBintara from "./hasil/bintara.vue";

export default {
  components: {
    HasilUtbk,
    HasilSma,
    HasilKedinasan,
    HasilMandiri,
    HasilBintara,
    BTabs,
    BTab,
    vSelect,
    flatPickr,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    VBTooltip,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentRasionalisasi: "HasilSma",
      formReset: {
        paket_id: null,
        ujian_id: null,
        jadwal_id: null,
        user_id: null,
        user_name: null,
      },
      m_reset: false,
      loading: false,
      dataMember: [],
      filterPaket: null,
      filterUjian: null,
      id: null,
      activeAction: null,
      label: "Simpan",
      jadwal: {
        paket: {
          rasionalisasi_type: "",
        },
      },
      //Data
      optujian: [],
      optpaket: [],
      optuser_id: [],
      jadwaladmindata: {},
      form: {
        paket_id: null,
        jadwal_id: null,
        ujian_id: null,
        start_time: null,
        end_time: null,
        user_id: null,
        user_name: null,
      },
      //Modal
      titleModal: null,
      m_calendar: false,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [],
      selected: [],
      total: [],
      record: [],
      optWarna: [
        { value: "primary", text: "primary" },
        { value: "info", text: "info" },
        { value: "secondary", text: "secondary" },
        { value: "warning", text: "warning" },
        { value: "success", text: "success" },
        { value: "light-primary", text: "light-primary" },
        { value: "light-info", text: "light-info" },
        { value: "light-secondary", text: "light-secondary" },
        { value: "light-warning", text: "light-warning" },
        { value: "light-success", text: "light-success" },
      ],
      optFilter: [
        { id: "name", value: "Kategori" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
      subtestList: [],
    };
  },
  watch: {},
  computed: {
    isSma() {
      if (!this.jadwal?.paket) {
        return null;
      }

      return this.jadwal.paket.rasionalisasi_type == "sma";
    },
    isUtbk() {
      if (!this.jadwal?.paket) {
        return null;
      }

      return this.jadwal.paket.rasionalisasi_type == "utbk";
    },
    isMandiri() {
      if (!this.jadwal?.paket) {
        return null;
      }
      return this.jadwal.paket.rasionalisasi_type == "mandiri";
    },
    isKedinasan() {
      if (!this.jadwal?.paket) {
        return null;
      }

      return this.jadwal.paket.rasionalisasi_type == "kedinasan";
    },
    isBintara() {
      if (!this.jadwal?.paket) {
        return null;
      }

      return this.jadwal.paket.rasionalisasi_type == "bintara";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    isFormResetValid() {
      if (
        !this.formReset.paket_id ||
        !this.formReset.ujian_id ||
        !this.formReset.jadwal_id
      ) {
        return false;
      }

      return true;
    },
    isFormRescheduleValid() {
      if (
        !this.form.paket_id ||
        !this.form.ujian_id ||
        !this.form.jadwal_id ||
        !this.form.start_time ||
        !this.form.end_time
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    openRescheduleModal(item) {
      this.form.jadwal_id = this.$route.params.id;
      this.form.paket_id = item.paket_id;
      this.form.user_id = item.user_id;
      this.form.user_name = item.user_name;
      this.$bvModal.show("modal-reschedule");
    },
    async reschedule() {
      if (!this.isFormRescheduleValid) {
        this.displayError({
          message: "Harap lengkapi form reschedule",
        });

        return false;
      }

      const payload = Object.assign({}, this.form);
      if (this.form.ujian_id && this.form.ujian_id.value) {
        payload.ujian_id = this.form.ujian_id.value;
      }

      try {
        this.$bvModal.hide("modal-reschedule");
        this.loading = true;
        const response = await this.$store.dispatch(
          "jadwaladmin/rescheduleUjian",
          [payload]
        );
        this.loading = false;
        this.displaySuccess({
          message:
            response.data && response.data.message
              ? response.data.message
              : "Berhasil direset",
        });
        this.getJadwal();
      } catch (e) {
        this.$bvModal.show("modal-reschedule");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    resetResult() {
      if (!this.isFormResetValid) {
        this.displayError({
          message: "Harap lengkapi form resetnya",
        });

        return false;
      }

      const payload = Object.assign({}, this.formReset);
      if (this.formReset.ujian_id && this.formReset.ujian_id.value) {
        payload.ujian_id = this.formReset.ujian_id.value;
      }

      this.$swal({
        title: "Anda Yakin?",
        text: "Hasil subjek mapel akan dihapus!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.$bvModal.hide("modal-reset");
            this.loading = true;
            const response = await this.$store.dispatch(
              "jadwaladmin/resetResult",
              [payload]
            );
            this.loading = false;
            this.displaySuccess({
              message:
                response.data && response.data.message
                  ? response.data.message
                  : "Berhasil direset",
            });
            this.getJadwal();
          } catch (e) {
            this.$bvModal.show("modal-reset");
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    openResetModal(item) {
      this.formReset.jadwal_id = this.$route.params.id;
      this.formReset.paket_id = item.paket_id;
      this.formReset.user_id = item.user_id;
      this.formReset.user_name = item.user_name;
      this.$bvModal.show("modal-reset");
    },
    getNilaiUjian(ujianName, item) {
      const { scores } = item;
      const myScore = scores.find(
        (score) => score.ujian_shortname == ujianName
      );

      return myScore ? myScore.nilai : 0.0;
    },
    printConsole(data) {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {},
    ModalCalendar(item) {
      this.id = null;
      // this.activeAction = "tambah";
      this.titleModal = "Atur Ulang Jadwal";
      this.m_calendar = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {},
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: data.id,
            fungsi: 2,
          };
          this.$store
            .dispatch("jadwaladmin/save", payload)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.paket_id == null || this.form.paket_id == "") {
        this.pesanGagal();
        return false;
      }

      var payload = {
        paket_id: this.form.paket_id,
        name: this.form.name,
        max_quota: this.form.max_quota,
        is_date_in_subject: this.form.is_date_in_subject == true ? 1 : 0,
        active: this.form.active == true ? 1 : 0,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("jadwaladmin/save", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Jadwal berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getJadwal() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "jadwaladmin/indexId",
          this.$route.params.id
        );
        if (!response.data) {
          this.$router.push("/master-admin/jadwal-admin");
        }

        this.jadwal = response.data;
        if (this.isSma) {
          this.currentRasionalisasi = "HasilSma";
        } else if (this.isUtbk) {
          this.currentRasionalisasi = "HasilUtbk";
        } else if (this.isMandiri) {
          this.currentRasionalisasi = "HasilMandiri";
        } else if (this.isKedinasan) {
          this.currentRasionalisasi = "HasilKedinasan";
        } else if (this.isBintara) {
          this.currentRasionalisasi = "HasilBintara";
        }
        await this.getResult();
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getResult() {
      const params = {
        jadwal_id: this.$route.params.id,
      };

      if (this.jadwal) {
        params.paket_id = this.jadwal.paket_id;
      }

      try {
        const response = await this.$store.dispatch(
          "jadwaladmin/getResult",
          params
        );
        const { ujians, data: members } = response;
        this.fields = [
          { key: "index", label: "No" },
          { key: "user_name", label: "Nama" },
        ];

        ujians.forEach((ujianName, i) => {
          this.fields.push({
            key: `ujian_${i}`,
            label: ujianName,
          });
        });

        this.fields.push({ key: "action", label: "#" });
        this.dataMember = members;

        if (this.dataMember.length > 0) {
          // get data list subtes
          const scores = this.dataMember[0].scores;
          this.subtestList = scores.map((subtest) => ({
            value: subtest.ujian_id,
            text: subtest.ujian_name,
          }));
        }
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
  },
  created() {
    this.getJadwal();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
